import { connect } from 'react-redux';
import Component from './component';

import {
    listSegments,
    presignFile,
} from 'Redux/modules/admin/userSegments/actions';
import {
    createMassMessage,
    updateMassMessage,
    deleteMassMessage,
} from 'Redux/modules/admin/chat/actions';

export default connect(
    state => ({
        segments: state.adminUserSegments.list,
    }),
    dispatch => ({
        actions: {
            listSegments: dispatch(listSegments),
            createMassMessage: dispatch(createMassMessage),
            updateMassMessage: dispatch(updateMassMessage),
            deleteMassMessage: dispatch(deleteMassMessage),
            presignFile: dispatch(presignFile),
        },
    })
)(Component);
