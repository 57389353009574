import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_CHAT, ADMIN_CHAT_MASS_MESSAGING_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import Spinner from 'Components/layout/Spinner';
import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import ChatMassMessagingForm from 'Components/admin/chatMassMessaging/ChatMassMessagingForm';

export default class AdminChatMassMessageManage extends Component {
    static propTypes = {
        actions: PropTypes.shape({
            singleMassMessage: PropTypes.func.isRequired,
        }).isRequired,
        massMessage: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.shape({
                id: PropTypes.string.isRequired,
                toSendAt: PropTypes.string.isRequired,
                chatConversationCategory: PropTypes.string.isRequired,
                status: PropTypes.string.isRequired,
            }),
            error: PropTypes.bool,
        }).isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    componentDidMount = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.singleMassMessage({ id: params.id });
    }

    render() {
        const { location, history, massMessage } = this.props;

        return (
            <StyledComponent
                className="page-user-segments-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_CHAT.path),
                        label: 'Czat',
                    }, {
                        to: withVariables(ADMIN_CHAT_MASS_MESSAGING_MANAGE.path),
                        label: 'Edycja masowej wysyłki',
                    }]}
                >
                    <ViewHeader
                        title="Edycja masowej wysyłki"
                    />
                    {!massMessage || massMessage.isLoading && <Spinner />}
                    {massMessage && massMessage.isLoaded && (
                        <ChatMassMessagingForm
                            history={history}
                            location={location}
                            massMessage={massMessage.data}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}